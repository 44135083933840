import React, { useEffect, useState } from 'react'

//Icons
import { FaTwitter } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'

//Imgs
import Pic from '../images/pic'

// Libs
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Link } from 'gatsby'

// Services
import { checkUser } from '../../services/auth'

const Footer = ({ menuLinks, auth }) => {
  const [isAuth, setAuth] = useState(auth || false)
  const [links, setLinks] = useState([])

  useEffect(() => {
    const buildMenu = menuLinks => {
      if (isAuth)
        menuLinks = menuLinks.filter(
          m => m.name !== 'Log in' && m.name !== 'Cotizar'
        )
      else
        menuLinks = menuLinks.filter(
          m => m.name !== 'Mi cuenta' && m.name !== 'Cerrar sesión'
        )
      const menu = menuLinks.map(({ name, link }) => (
        <li className="lg:inline-block mb-0 mr-4 font-medium" key={name}>
          <AniLink
            duration={0.3}
            className="text-grayText transition duration-700 hover:text-standard-blue"
            fade
            to={link}
            state={{ scroll: name === '¿Por qué Alex?' ? 'blue' : false }}
          >
            {name}
          </AniLink>
        </li>
      ))
      setLinks(menu)
    }
    buildMenu(menuLinks)
  }, [isAuth, menuLinks])

  /**
   * Checks if user is logged in
   * when the header is instanciated
   */
  const checkUserAuth = async () => {
    const isAuthenticated = await checkUser()
    setAuth(!!isAuthenticated)
  }

  // Calling it once
  checkUserAuth()

  return (
    <>
      <div className="grid grid-cols-12 bg-gray pt-16 pb-2">
        <div className="col-start-2 col-span-6 lg:col-span-2 lg:col-start-3">
          <div className="mb-4 w-3/4">
            <AniLink duration={0.3} fade to="/">
              <Pic filename="logo/alex-bluex.png" />
            </AniLink>
            <div className="flex text-xss items-center lg:text-base my-2">
              <p className="flex-initial -mt-2 lg:ml-2 mb-0">por</p>
              <div className="flex-1">
                <Pic filename="logo/redbridge-new.png" />
              </div>
            </div>
          </div>
          <div className="flex">
            <Link to="https://twitter.com/alexseguro_com">
              <FaTwitter className="mr-3" />
            </Link>
            <Link to="https://instagram.com/alexseguro_com">
              <FaInstagram className="mr-3" />
            </Link>
            <Link to="https://www.youtube.com/watch?v=GsBtEgZU-aY">
              <FaYoutube />
            </Link>
          </div>
        </div>
        <div className="pt-16 lg:pt-0 col-start-2 col-span-5 lg:col-start-6 lg:col-span-2">
          <h2 className="text-xs lg:text-lg">Secciones</h2>
          <ul className="text-xss lg:text-xs grid mb-0 ml-0">{links}</ul>
        </div>
        <div className="pt-16 lg:pt-0 col-start-8 col-span-4 lg:col-start-8 lg:col-span-2 mb-8">
          <h2 className="text-xs lg:text-lg">Contáctanos</h2>
          <p className="text-xss lg:text-xs m-0 font-bold">
            Oficina Administrativa
          </p>
          <p className="text-xss lg:text-xs m-0 leading-snug">
            1300 Ponce de Leon Blvd{' '}
          </p>
          <p className="text-xss lg:text-xs m-0 leading-snug">Suite 103</p>
          <p className="text-xss lg:text-xs m-0 leading-snug">
            Coral Gables, 33134
          </p>
          <p className="text-xss lg:text-xs m-0 leading-snug">Florida, USA</p>
          <p className="text-xss lg:text-xs m-0 hover:text-gray-600">
            <a href="tel:+13052329040">(305) 232-9040</a>
          </p>
          <p className="text-xss lg:text-xs m-0 hover:text-gray-600 hover:underline leading-snug">
            <a href="mailto:soporte@alexseguro.com">soporte@alexseguro.com</a>
          </p>
        </div>
        <div className="mt-4 lg:mt-0 col-start-2 col-span-10 lg:col-start-10 lg:col-span-2 text-left">
          <h2 className="text-xs lg:text-lg">Legal</h2>
          <p className="text-xss lg:text-xs m-0 font-semibold">
            Redbridge Group of Florida, Inc.{' '}
          </p>
          <AniLink to="/politica-de-privacidad">
            <p className="text-xss lg:text-xs m-0 hover:text-gray-600">
              Política de Privacidad
            </p>
          </AniLink>
          <AniLink to="/terminos-y-condiciones">
            <p className="text-xss lg:text-xs m-0 hover:text-gray-600">
              Términos y Condiciones
            </p>
          </AniLink>
          <p className="m-0 text-xss">
            &copy; Alex Seguros 2020. All Rights Reserved.
          </p>
        </div>
        <div className="lg:col-start-3 lg:col-span-1 col-start-2 col-span-3 mt-4">
          <Pic filename="home/amBest.png" />
        </div>
        <div className="lg:col-start-3 lg:col-span-8 col-start-2 col-span-10 mt-4 text-grayText text-left">
          <p className="text-xss leading-normal">
            Los planes Alex son suscritos por Redbridge Insurance Company, Ltd.,
            una acompañia de seguros registrada en Bárbados. La venta y
            distribución de los planes Alex no está autorizada en los EE.UU.{' '}
            <br />
            Redbridge Insurance Company, Ltd es una compañía de seguros
            registrada en Barbados, calificada B++ Good por A.M. Best.
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer

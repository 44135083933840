// React
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import classnames from "classnames"

// Services
import { checkUser } from "../../services/auth"

// Components
import Pic from "../images/pic"

// CSS
import "./header.css"
import "./animations.css"

const Header = ({ menuLinks, sliderIsOpened, auth }) => {
  const [click, setClicked] = useState("null")
  const [hasScrolled, scrolling] = useState(false)
  const [isAuth, setAuth] = useState(auth || false)
  const [links, setLinks] = useState([])

  useEffect(() => {
    const buildMenu = menuLinks => {
      if (isAuth)
        menuLinks = menuLinks.filter(
          m => m.name !== "Log in" && m.name !== "Cotizar"
        )
      else
        menuLinks = menuLinks.filter(
          m => m.name !== "Mi cuenta" && m.name !== "Cerrar sesión"
        )
      const menu = menuLinks.map(({ name, link, type }) =>
        type === "link" ? (
          <li
            className="lg:inline-block mb-0 mr-4 text-header font-medium"
            key={name}
          >
            <AniLink
              duration={0.3}
              className="text-blue transition duration-700 hover:text-standard-blue"
              fade
              to={link}
              state={{ scroll: name === "¿Por qué Alex?" ? "blue" : false }}
            >
              {name}
            </AniLink>
          </li>
        ) : (
          <li
            className="lg:inline-block mb-0 mr-4 text-header font-medium"
            key={name}
          >
            {name === "Log in" || name === "Mi cuenta" ? (
              <AniLink duration={0.3} fade to={link}>
                <button
                  className="outlined-default-button py-3 px-5"
                  type="button"
                >
                  {name}
                </button>
              </AniLink>
            ) : (
              <AniLink duration={0.3} fade to={link}>
                <button className="default-button py-3 px-4" type="button">
                  {name}
                </button>
              </AniLink>
            )}
          </li>
        )
      )
      setLinks(menu)
    }
    buildMenu(menuLinks)
  }, [isAuth, menuLinks])

  /**
   * Checks if user is logged in
   * when the header is instanciated
   */
  const checkUserAuth = async () => {
    const isAuthenticated = await checkUser()
    setAuth(!!isAuthenticated)
  }

  // Calling it once
  checkUserAuth()

  let headerClass = classnames(
    "fixed",
    "top-0",
    "w-full",
    "z-50",
    "bg-white",
    "mb-10",
    hasScrolled ? ["border-b", "w-screen", "shadow"] : ""
  )
  let navClass = classnames(
    "lg:col-start-2",
    "lg:col-end-12",
    "lg:pl-0",
    "pl-10",
    "lg:pr-0",
    "pr-10",
    "col-span-12",
    "mb",
    { "border-b": !hasScrolled }
  )

  if (typeof window !== "undefined") {
    // Setting up scroll listener
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0
      ) {
        scrolling(true)
      } else scrolling(false)
    })
  }

  const headerControl = () => {
    setClicked(!!click)
    sliderIsOpened(!!click)
  }

  return (
    <header className={headerClass} id="header">
      <div className="grid grid-cols-12">
        <nav id="nav" className={navClass}>
          <div className="mb-4 flex-shrink p-1 items-center -ml-5 lg:ml-0 xl:pedro-xs lg:pedro-sm md:pedro-md pedro-lg">
            <AniLink state={{ scroll: "top" }} duration={0.3} fade to="/">
              <Pic filename="/alex-bluex.png" />
            </AniLink>
          </div>
          <div
            role="row"
            tabIndex={0}
            className="flex lg:hidden"
            onClick={() => {
              headerControl()
            }}
            onKeyDown={() => {}}
          >
            <div className="mobile-menu">
              <div id="menu_icon">
                <span className="material-icons">menu</span>
              </div>
            </div>
          </div>
          <div className="items-center mb-4 justify-end hidden lg:block">
            <ul className="text-sm mb-0">{links}</ul>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

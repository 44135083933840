import React, { useState } from 'react'

// Events
import { EventEmitter } from '../../services/events'

// Libs
import classnames from 'classnames'

import LoadingGIF from '../../images/modal/loading-pedro.gif'

// Styles
import modalStyles from './modal.module.css'

/**
 * Modal component
 * @param {*} param0
 */
const LoadingModal = () => {
  const [modalObj, toggleModal] = useState({ show: false })
  EventEmitter.subscribe('toggleLoading', event => toggleModal(event))
  const { show } = modalObj
  const container = classnames(modalStyles.centered, {
    [modalStyles.show]: !!show,
  })
  const bg = classnames(modalStyles.modalBg, { [modalStyles.show]: !!show })

  return (
    <>
      <div
        onKeyDown={() => {}}
        role="button"
        aria-label="Close modal"
        tabIndex={0}
        onClick={() => toggleModal(false)}
        className={bg}
      ></div>
      <div className={container}>
        <img src={LoadingGIF} alt="Loading GIF" />
      </div>
    </>
  )
}

export default LoadingModal

import firebase, { LocalSession } from './firebase'

// Services
import { EventEmitter } from './events'
/**
 * Makes sure the window object is present
 */
export const isBrowser = () => typeof window !== 'undefined'

/**
 * Returns local user data from
 * localStorage
 */
export const getLocalUser = () =>
  isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
    : {}

/**
 * Saves user object to localStorage
 * @param {Object} user - User object
 */
export const setUser = user =>
  window.localStorage.setItem('gatsbyUser', JSON.stringify(user))

/**
 *
 * @param {Object} request
 * @param {String} request.email - User's email
 * @param {String} request.password - User's password
 * @param {Object} request.user - User object
 */
export const handleRegister = async ({
  email,
  password,
  user,
  quote,
  selected,
  refCode,
}) => {
  try {
    EventEmitter.dispatch('toggleLoading', { show: true })
    const response = await firebase.auth.createUserWithEmailAndPassword(
      email,
      password
    )
    const userId = response.user.uid
    if (!userId) {
      EventEmitter.dispatch('toggleLoading', { show: false })
      EventEmitter.dispatch('toggleModal', {
        show: true,
        success: false,
        title: '¡Lo sentimos!',
        message:
          'Parece que ha habido un problema al registrar tu cuenta. Te rogamos que intentes de nuevo.',
      })

      return { success: false }
    }
    const userPayload = {
      ...user,
      userId,
      createdAt: new Date(),
    }
    if (refCode) {
      userPayload.broker = refCode
    }
    await Promise.all([
      firebase.db
        .collection('/users')
        .doc(userId)
        .set(userPayload),
      firebase.db
        .collection('/policies')
        .doc(userId)
        .set({ userId, ...selected }),
      firebase.db
        .collection('/user-quotes')
        .doc(userId)
        .set({ userId, ...quote }),
    ])
    setUser(userPayload)
    EventEmitter.dispatch('toggleLoading', { show: false })
    EventEmitter.dispatch('toggleModal', {
      show: true,
      success: true,
      title: 'Registro exitoso',
      message:
        'Por favor dirijase a su buzón de entrada y confirme su correo electrónico',
      navigateTo: '/users/checkout',
      routeParams: { selected: selected, type: 'signup' },
    })
    return { success: true, data: userPayload }
  } catch (e) {
    console.log(e)
    EventEmitter.dispatch('toggleLoading', { show: false })
    switch (e.code) {
      case 'auth/email-already-in-use':
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: '¡Lo sentimos!',
          message:
            'Este correo electrónico ya esta asociado con una cuenta Alex',
        })
        break
      default:
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: '¡Lo sentimos!',
          message: 'En este momento no podemos procesar tu registro.',
        })
        break
    }
    return { success: false, error: e }
  }
}

/**
 *
 * @param {Object} request
 * @param {String} request.userId - User's ID
 */
export const userRef = async ({ userId }) => {
  try {
    return await firebase.db.collection('users').doc(userId)
  } catch (e) {
    console.error(`Unable to obtain reference for ${userId}`)
  }
}

/**
 * Handles login authentication
 * @param {Object} request
 * @param {String} email - User's email
 * @param {String} password - User's password
 */
export const handleLogin = async ({ email, password }) => {
  try {
    EventEmitter.dispatch('toggleLoading', { show: true })
    await firebase.auth.setPersistence(LocalSession)
    await firebase.auth.signInWithEmailAndPassword(email, password)
    const { uid } = await firebase.auth.currentUser
    const user = await firebase.db
      .collection('users')
      .doc(uid)
      .get()
    EventEmitter.dispatch('toggleLoading', { show: false })
    if (user.exists) {
      setUser(user.data())
      return { success: true, user: user.data() }
    } else return { success: false }
  } catch (e) {
    switch (e.code) {
      case 'auth/wrong-password':
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: '¡Oops!',
          message: 'Ha introducido erroneamente su contraseña.',
        })
        break
      case 'auth/user-not-found':
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: '¡Oops!',
          message:
            'Parece que ese correo electrónico no se encuentra registrado.',
        })
        break
      default:
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: '¡Lo sentimos!',
          message: 'Por favor, revisa tus credenciales e intenta de nuevo.',
        })
        break
    }
    EventEmitter.dispatch('toggleLoading', { show: false })
    return { success: false }
  }
}

/**
 *
 * @param {Object} request
 * @param {String} request.email - User's email
 */
export const resetPassword = async ({ email }) => {
  try {
    EventEmitter.dispatch('toggleLoading', { show: true })
    await firebase.auth.sendPasswordResetEmail(email)
    EventEmitter.dispatch('toggleLoading', { show: false })
    EventEmitter.dispatch('toggleModal', {
      show: true,
      success: true,
      title: 'Correo enviado',
      message: 'Revisa tu correo para restablecer tu contraseña.',
      navigateTo: '/auth/login',
    })
    return { success: true }
  } catch (e) {
    EventEmitter.dispatch('toggleLoading', { show: false })
    switch (e.code) {
      case 'auth/user-not-found':
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: '¡Oops!',
          message:
            'Parece que ese correo electrónico no se encuentra registrado.',
        })
        break
      default:
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: '¡Lo sentimos!',
          message: 'Parece haber un problema. Por favor intenta más tarde.',
        })
        break
    }
    return { success: false }
  }
}

/**
 * Checks if user is logged in
 */
export const checkUser = async () => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth.onAuthStateChanged(user => {
        let local = getLocalUser()
        if (!!user && Object.keys(local).length > 0) {
          resolve(user.uid)
        } else resolve(false)
      })
    } catch (e) {
      reject(false)
    }
  })
}

export const checkUserVerification = async () => {
  return new Promise((resolve, reject) => {
    try {
      firebase.auth.onAuthStateChanged(user => {
        let local = getLocalUser()
        if (!!user && Object.keys(local).length > 0) {
          resolve(user.emailVerified)
        } else resolve(false)
      })
    } catch (e) {
      reject(false)
    }
  })
}

export const logout = callback => {
  setUser({})
  callback()
}

import React, { useState } from 'react'
// Gatsby
import { navigate } from 'gatsby'

// Components
import SuccessIcon from '../images/success-icon'
import FailureIcon from '../images/failure-icon'

// Libs
import classnames from 'classnames'

// Events
import { EventEmitter } from '../../services/events'

// Styles
import modalStyles from './modal.module.css'

/**
 * Modal component
 * @param {*} param0
 */
const Modal = () => {
  const [modalObj, toggleModal] = useState({
    title: 'Test message',
    message: 'This is a test content for the modal component',
    plan: 'Alex Full',
    email: false,
    show: false,
    navigateTo: false,
    routeParams: null,
    content: '',
    noIcon: false,
  })
  EventEmitter.subscribe('toggleModal', event => toggleModal(event))
  const {
    title,
    message,
    plan,
    email,
    show,
    navigateTo,
    success,
    routeParams,
    content,
    noIcon,
    buttons,
  } = modalObj
  const container = classnames(modalStyles.modalContainer, {
    [modalStyles.show]: !!show,
  })
  const bg = classnames(modalStyles.modalBg, { [modalStyles.show]: !!show })

  return (
    <>
      <div
        onKeyDown={() => {}}
        role="button"
        aria-label="Close modal"
        tabIndex={0}
        onClick={() => {
          toggleModal(false)
          if (navigateTo) {
            navigate(navigateTo, { state: routeParams })
          }
        }}
        className={bg}
      ></div>
      <div className={container}>
        <div className={modalStyles.modalHead}>
          {/* <div onKeyDown={() => { }} role="button" tabIndex={0} onClick={() => toggleModal(false)} className={modalStyles.closeButton}>X</div> */}
        </div>
        <div className="grid grid-cols-12 modal-body">
          {!noIcon && (
            <div className="col-span-12 w-1/4 mx-auto p-3 mt-1 mb-2">
              {!!success ? <SuccessIcon /> : <FailureIcon />}
            </div>
          )}
          <div className="mt-2 col-span-12 mx-auto">
            <h3>{title}</h3>
          </div>
          <div className="col-span-12 mx-auto px-4 text-center">
            {content}
            <p>{message}</p>
          </div>
          {plan && (
            <div className="col-span-12 mx-auto">
              <h3 className="text-blue">{plan}</h3>
            </div>
          )}
          {email && (
            <div className="col-span-12 mx-auto">
              <p>{email}</p>
            </div>
          )}
          {buttons ? (
            <>
              <div className="col-span-12 mx-auto">
                <p className="text-grayText">¿Qué deseas hacer?</p>
              </div>
              {buttons.map((button, index) => (
                <div key={index} className="col-span-12 mx-auto mb-5">
                  <button
                    onClick={() => {
                      toggleModal(false)
                      if (button.navigateTo) {
                        navigate(button.navigateTo, { state: routeParams })
                      }
                    }}
                    className={classnames(
                      'py-3 px-5 cursor-pointer',
                      modalStyles.closeButton,
                      {
                        'outlined-default-button': index % 2 === 0,
                        'default-button': index % 2 !== 0,
                      }
                    )}
                  >
                    {button.text}
                  </button>
                </div>
              ))}
            </>
          ) : (
            <div className="col-span-12 mx-auto mb-5">
              <button
                onClick={() => {
                  toggleModal(false)
                  if (navigateTo) {
                    navigate(navigateTo, { state: routeParams })
                  }
                }}
                className={classnames(
                  'default-button py-3 px-5 cursor-pointer',
                  modalStyles.closeButton
                )}
              >
                Hecho
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Modal

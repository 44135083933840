import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import WhatsApp from '../../images/home/wasvg.svg'

import classnames from 'classnames'

// Components
import SideBar from '../header/sidebar'
import Header from '../header/header'
import Footer from '../footer/footer'
import PrivateMenu from '../navigation/private-area-menu'

// Modals
import Modal from '../modals/modal'
import LoadingModal from '../modals/loading'

//CSS
import './index.css'
import './layout.css'
import './animations.css'

// Services
import { getLocalUser } from '../../services/auth'
import firebase from '../../services/firebase'

// SVG
// import WhatsAppIcon from '../../svg/whatsapp-icon-seeklogo.com.svg'

const Layout = ({ children, auth }) => {
  const { userId } = getLocalUser()
  const [user, setUser] = useState()
  const [hasBeneficiaries, setHasBeneficiaries] = useState(false)
  const [isOpen, headerControl] = useState(null)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
            type
            protected
          }
          userLinks {
            name
            link
          }
        }
      }
    }
  `)

  useEffect(() => {
    const loadUser = async () => {
      if (userId) {
        let userQuery = await firebase.db
          .collection('users')
          .doc(userId)
          .get()
        let beneficiaries = await firebase.db
          .collection('beneficiaries')
          .where('userId', '==', userId)
          .get()
        setUser(userQuery.data())
        setHasBeneficiaries(beneficiaries.size > 0)
      }
    }
    loadUser()
  }, [userId])

  /**
   * React hook to handle close button
   * click on header component
   */
  const closeHeader = () => {
    headerControl(false)
  }

  let sliderOpenClass = classnames(
    { 'slider-open': isOpen },
    { 'slider-close': !isOpen && isOpen !== null }
  )
  let { menuLinks, userLinks, title } = data.site.siteMetadata
  return (
    <>
      <Modal />
      <LoadingModal />
      <SideBar
        menuLinks={menuLinks}
        isOpen={isOpen}
        closeHeader={closeHeader}
        auth={auth}
      />
      <div className={sliderOpenClass}>
        <Header
          menuLinks={menuLinks}
          siteTitle={title}
          sliderIsOpened={headerControl}
          auth={auth}
        />
        {auth ? (
          <main className="grid grid-cols-12">
            <div className="lg:col-span-2 lg:col-start-2 col-start-2 col-span-12 lg:mt-40 mt-24">
              {user && user.payed && user.documentId && hasBeneficiaries && (
                <PrivateMenu userLinks={userLinks} />
              )}
            </div>
            <div className="lg:col-span-8 col-span-12">{children}</div>
          </main>
        ) : (
          <main>{children}</main>
        )}
        <a
          href="https://wa.me/13057784490"
          className="fixed left-0 bottom-0 z-10"
        >
          <WhatsApp width={125} height={125} />
        </a>
        <footer>
          <Footer menuLinks={data.site.siteMetadata.menuLinks} auth={auth} />
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"


const PrivateAreaMenu = ({ userLinks }) => {
    return (
        <div className="lg:border-0 border-b-2">
            <h2 className="font-bold">Mi cuenta</h2>
            <ul className="menu-links ml-0">
                {userLinks && userLinks.length && (
                    userLinks.map((l, i) => {
                        return (
                            <li key={i} >
                                <AniLink className="text-grayText transition duration-1000 hover:text-standard-blue" fade to={l.link}>{l.name}</AniLink>
                            </li>
                        )
                    })
                )}
            </ul>
        </div>
    )

}

export default PrivateAreaMenu
// React
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import classnames from "classnames"
// Icons
import { FaTwitter, FaInstagram } from 'react-icons/fa'
// Libs
import AniLink from 'gatsby-plugin-transition-link/AniLink'
// Services
import { checkUser } from '../../services/auth'
// CSS
import "./header.css"
import "./animations.css"

const SideBar = ({ menuLinks, isOpen, closeHeader, auth }) => {

  const [isAuth, setAuth] = useState(auth || false)
  const [links, setLinks] = useState([])
  let classNames = classnames({ "sidebar-open": isOpen }, { "sidebar-closed": !isOpen && isOpen !== null })

  if (!isOpen) {
    setTimeout(() => { classNames = "" }, 2000)
  }

  useEffect(() => {
    const buildMenu = (menuLinks) => {
      if (isAuth) menuLinks = menuLinks.filter(m => m.name !== "Log in" && m.name !== "Cotizar")
      else menuLinks = menuLinks.filter(m => m.name !== "Mi cuenta" && m.name !== "Cerrar sesión")
      const menu = menuLinks.map(({ name, link }) => (
        <li
          className="lg:inline-block my-3 mr-4 text-header font-medium"
          key={name}>
          <AniLink duration={0.3}
            className="text-grayText text-lg transition duration-700 hover:text-standard-blue"
            fade to={link}
            state={{ scroll: name === "¿Por qué Alex?" ? "blue" : false }}>
            {name}
          </AniLink>
        </li>
      ))
      setLinks(menu)
    }
    buildMenu(menuLinks)
  }, [isAuth, menuLinks])

  /**
   * Checks if user is logged in
   * when the header is instanciated
   */
  const checkUserAuth = async () => {
    const isAuthenticated = await checkUser()
    setAuth(!!isAuthenticated)
  }

  // Calling it once
  checkUserAuth()


  return (
    <div id="slider" role="row" tabIndex={0} className={classNames} onClick={() => closeHeader()} onKeyDown={() => { }}>
      <span className="material-icons slider-icon linear hover:text-white">close</span>
      <ul className="text-sm mb-0 mt-20 ml-10">
        {links}
      </ul>
      <div className="social-icons justify-left">
        <div className="flex mb-4 ml-8">
          <div className="social-icon">
            <a rel="noopener noreferrer" href="https://twitter.com/alexseguro_com" target="_blank">
              <FaTwitter href="#" className="text-grayText" />
            </a>
          </div>
          <a rel="noopener noreferrer" href="https://instagram.com/alexseguro_com" target="_blank">
            <div className="social-icon">
              <FaInstagram className="text-grayText" />
            </div>
          </a>
        </div>
        <p className="copyrights">&copy; Alex Seguros 2020</p>
      </div>
    </div>
  )
}

SideBar.propTypes = {
  siteTitle: PropTypes.string,
}

SideBar.defaultProps = {
  siteTitle: ``,
}

export default SideBar
